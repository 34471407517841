import img1 from "../assets/caravan-2.png";
import img2 from '../assets/caravan.png'
import img3 from '../assets/caravan-3.jpg'
import img4 from "../assets/caravan-4.webp";
import img5 from '../assets/caravan-5.jpeg'
import img6 from '../assets/caravan-6.jpeg'
import img7 from "../assets/caravan-7.jpeg";
import img8 from '../assets/caravan-8.jpeg'
import img9 from "../assets/caravan-9.png";
import img10 from "../assets/caravan-10.png";
import img11 from "../assets/caravan-11.webp";
import img12 from "../assets/caravan-12.jpeg";
import img13 from "../assets/caravan-13.png";
import img14 from "../assets/caravan-14.webp";
import img15 from "../assets/caravan-15.png";
import img16 from "../assets/caravan-16.jpeg";
import img17 from "../assets/caravan-1.png";
import img18 from "../assets/caravan-2.png";
import img19 from "../assets/caravan-3.jpg";
import img20 from "../assets/caravan-4.webp";

export const caravan = {
  maindata: [
    {
      id: 1,
      caravan: {
        image: `${img1}`,
        name: "Camp Caravan 1",
        price: "2,123,000",
        company: "깜찍한 캠핑카",
        rate: "5.3",
        license: "1종 보통",
        people: "6인",
        type: "피아트 듀카토",
        location: "수도권",
      },
      docs: {
        comfort: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.
        `,
        tidying: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.`,
        ventilated: `In our vans and motorhomes, all our body lighting is 100% LED: a guarantee of energy efficiency and durability.
As for ventilation, we also often offer double ventilation in the bathroom (Skylight + window), which is very popular with our customers…
Camper relies on legendary Seitz windows. They offer the best insulation and are also the easiest to use and safest because they can’t be removed from the outside.												
					`,
        access: `We add little touches that your joints will thank you for…
          All our motorhomes are equipped with a built-in step to facilitate entry to the vehicle
          All our vans are equipped with an electric step.`,
        heating: `Fuel heating is now very popular in the industry, but this was not always the case; Camper (once again) led the way in this area over 15 years ago.
          One of the advantages it offers is that it can heat up your vehicle while you drive, for a more comfortable arrival.`,
      },
    },
    {
      id: 2,
      caravan: {
        image: `${img2}`,
        name: "Camp Caravan 2",
        price: "1,230,000",
        company: "깜찍한 캠핑카",
        rate: "5.0",
        license: "1종 보통",
        people: "4인",
        type: "르노마스터",
        location: "경상권",
      },
      docs: {
        comfort: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.
        `,
        tidying: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.`,
        ventilated: `In our vans and motorhomes, all our body lighting is 100% LED: a guarantee of energy efficiency and durability.
As for ventilation, we also often offer double ventilation in the bathroom (Skylight + window), which is very popular with our customers…
Camper relies on legendary Seitz windows. They offer the best insulation and are also the easiest to use and safest because they can’t be removed from the outside.												
					`,
        access: `We add little touches that your joints will thank you for…
          All our motorhomes are equipped with a built-in step to facilitate entry to the vehicle
          All our vans are equipped with an electric step.`,
        heating: `Fuel heating is now very popular in the industry, but this was not always the case; Camper (once again) led the way in this area over 15 years ago.
          One of the advantages it offers is that it can heat up your vehicle while you drive, for a more comfortable arrival.`,
      },
    },
    {
      id: 3,
      caravan: {
        image: `${img3}`,
        name: "Camp Caravan 3",
        price: "999,000",
        company: "모험의 바퀴",
        rate: "8.9",
        license: "2종 보통",
        people: "4인",
        type: "피아트 듀카토",
        location: "수도권",
      },
      docs: {
        comfort: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.
        `,
        tidying: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.`,
        ventilated: `In our vans and motorhomes, all our body lighting is 100% LED: a guarantee of energy efficiency and durability.
As for ventilation, we also often offer double ventilation in the bathroom (Skylight + window), which is very popular with our customers…
Camper relies on legendary Seitz windows. They offer the best insulation and are also the easiest to use and safest because they can’t be removed from the outside.												
					`,
        access: `We add little touches that your joints will thank you for…
          All our motorhomes are equipped with a built-in step to facilitate entry to the vehicle
          All our vans are equipped with an electric step.`,
        heating: `Fuel heating is now very popular in the industry, but this was not always the case; Camper (once again) led the way in this area over 15 years ago.
          One of the advantages it offers is that it can heat up your vehicle while you drive, for a more comfortable arrival.`,
      },
    },
    {
      id: 4,
      caravan: {
        image: `${img4}`,
        name: "Camp Caravan 4",
        price: "3,000,000",
        company: "모험의 바퀴",
        rate: "2.7",
        license: "1종 보통",
        people: "5인",
        type: "르노마스터",
        location: "경상권",
      },
      docs: {
        comfort: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.
        `,
        tidying: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.`,
        ventilated: `In our vans and motorhomes, all our body lighting is 100% LED: a guarantee of energy efficiency and durability.
As for ventilation, we also often offer double ventilation in the bathroom (Skylight + window), which is very popular with our customers…
Camper relies on legendary Seitz windows. They offer the best insulation and are also the easiest to use and safest because they can’t be removed from the outside.												
					`,
        access: `We add little touches that your joints will thank you for…
          All our motorhomes are equipped with a built-in step to facilitate entry to the vehicle
          All our vans are equipped with an electric step.`,
        heating: `Fuel heating is now very popular in the industry, but this was not always the case; Camper (once again) led the way in this area over 15 years ago.
          One of the advantages it offers is that it can heat up your vehicle while you drive, for a more comfortable arrival.`,
      },
    },
    {
      id: 5,
      caravan: {
        image: `${img5}`,
        name: "Camp Caravan 5",
        price: "1,000,000",
        company: "깜찍한 캠핑카",
        rate: "9.5",
        license: "1종 보통",
        people: "3인",
        type: "피아트 듀카토",
        location: "수도권",
      },
      docs: {
        comfort: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.
        `,
        tidying: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.`,
        ventilated: `In our vans and motorhomes, all our body lighting is 100% LED: a guarantee of energy efficiency and durability.
As for ventilation, we also often offer double ventilation in the bathroom (Skylight + window), which is very popular with our customers…
Camper relies on legendary Seitz windows. They offer the best insulation and are also the easiest to use and safest because they can’t be removed from the outside.												
					`,
        access: `We add little touches that your joints will thank you for…
          All our motorhomes are equipped with a built-in step to facilitate entry to the vehicle
          All our vans are equipped with an electric step.`,
        heating: `Fuel heating is now very popular in the industry, but this was not always the case; Camper (once again) led the way in this area over 15 years ago.
          One of the advantages it offers is that it can heat up your vehicle while you drive, for a more comfortable arrival.`,
      },
    },
    {
      id: 6,
      caravan: {
        image: `${img6}`,
        name: "Camp Caravan 6",
        price: "859,000",
        company: "모험의 바퀴",
        rate: "9.1",
        license: "1종 보통",
        people: "4인",
        type: "포터",
        location: "수도권",
      },
      docs: {
        comfort: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.
        `,
        tidying: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.`,
        ventilated: `In our vans and motorhomes, all our body lighting is 100% LED: a guarantee of energy efficiency and durability.
As for ventilation, we also often offer double ventilation in the bathroom (Skylight + window), which is very popular with our customers…
Camper relies on legendary Seitz windows. They offer the best insulation and are also the easiest to use and safest because they can’t be removed from the outside.												
					`,
        access: `We add little touches that your joints will thank you for…
          All our motorhomes are equipped with a built-in step to facilitate entry to the vehicle
          All our vans are equipped with an electric step.`,
        heating: `Fuel heating is now very popular in the industry, but this was not always the case; Camper (once again) led the way in this area over 15 years ago.
          One of the advantages it offers is that it can heat up your vehicle while you drive, for a more comfortable arrival.`,
      },
    },
    {
      id: 7,
      caravan: {
        image: `${img7}`,
        name: "Camp Caravan 7",
        price: "2,000,000",
        company: "깜찍한 캠핑카",
        rate: "5.5",
        license: "2종 보통",
        people: "3인",
        type: "피아트 듀카토",
        location: "경상권",
      },
      docs: {
        comfort: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.
        `,
        tidying: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.`,
        ventilated: `In our vans and motorhomes, all our body lighting is 100% LED: a guarantee of energy efficiency and durability.
As for ventilation, we also often offer double ventilation in the bathroom (Skylight + window), which is very popular with our customers…
Camper relies on legendary Seitz windows. They offer the best insulation and are also the easiest to use and safest because they can’t be removed from the outside.												
					`,
        access: `We add little touches that your joints will thank you for…
          All our motorhomes are equipped with a built-in step to facilitate entry to the vehicle
          All our vans are equipped with an electric step.`,
        heating: `Fuel heating is now very popular in the industry, but this was not always the case; Camper (once again) led the way in this area over 15 years ago.
          One of the advantages it offers is that it can heat up your vehicle while you drive, for a more comfortable arrival.`,
      },
    },
    {
      id: 8,
      caravan: {
        image: `${img8}`,
        name: "Camp Caravan 8",
        price: "450,000",
        company: "모험의 바퀴",
        rate: "3.1",
        license: "1종 보통",
        people: "4인",
        type: "피아트 듀카토",
        location: "수도권",
      },
      docs: {
        comfort: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.
        `,
        tidying: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.`,
        ventilated: `In our vans and motorhomes, all our body lighting is 100% LED: a guarantee of energy efficiency and durability.
As for ventilation, we also often offer double ventilation in the bathroom (Skylight + window), which is very popular with our customers…
Camper relies on legendary Seitz windows. They offer the best insulation and are also the easiest to use and safest because they can’t be removed from the outside.												
					`,
        access: `We add little touches that your joints will thank you for…
          All our motorhomes are equipped with a built-in step to facilitate entry to the vehicle
          All our vans are equipped with an electric step.`,
        heating: `Fuel heating is now very popular in the industry, but this was not always the case; Camper (once again) led the way in this area over 15 years ago.
          One of the advantages it offers is that it can heat up your vehicle while you drive, for a more comfortable arrival.`,
      },
    },
    {
      id: 9,
      caravan: {
        image: `${img9}`,
        name: "Camp Caravan 9",
        price: "830,500",
        company: "행복 바퀴벌레",
        rate: "8.9",
        license: "2종 보통",
        people: "3인",
        type: "르노마스터",
        location: "수도권",
      },
      docs: {
        comfort: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.
        `,
        tidying: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.`,
        ventilated: `In our vans and motorhomes, all our body lighting is 100% LED: a guarantee of energy efficiency and durability.
As for ventilation, we also often offer double ventilation in the bathroom (Skylight + window), which is very popular with our customers…
Camper relies on legendary Seitz windows. They offer the best insulation and are also the easiest to use and safest because they can’t be removed from the outside.												
					`,
        access: `We add little touches that your joints will thank you for…
          All our motorhomes are equipped with a built-in step to facilitate entry to the vehicle
          All our vans are equipped with an electric step.`,
        heating: `Fuel heating is now very popular in the industry, but this was not always the case; Camper (once again) led the way in this area over 15 years ago.
          One of the advantages it offers is that it can heat up your vehicle while you drive, for a more comfortable arrival.`,
      },
    },
    {
      id: 10,
      caravan: {
        image: `${img10}`,
        name: "Camp Caravan 10",
        price: "250,000",
        company: "모험의 바퀴",
        rate: "4.5",
        license: "1종 보통",
        people: "5인",
        type: "포터",
        location: "경상권",
      },
      docs: {
        comfort: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.
        `,
        tidying: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.`,
        ventilated: `In our vans and motorhomes, all our body lighting is 100% LED: a guarantee of energy efficiency and durability.
As for ventilation, we also often offer double ventilation in the bathroom (Skylight + window), which is very popular with our customers…
Camper relies on legendary Seitz windows. They offer the best insulation and are also the easiest to use and safest because they can’t be removed from the outside.												
					`,
        access: `We add little touches that your joints will thank you for…
          All our motorhomes are equipped with a built-in step to facilitate entry to the vehicle
          All our vans are equipped with an electric step.`,
        heating: `Fuel heating is now very popular in the industry, but this was not always the case; Camper (once again) led the way in this area over 15 years ago.
          One of the advantages it offers is that it can heat up your vehicle while you drive, for a more comfortable arrival.`,
      },
    },
    {
      id: 11,
      caravan: {
        image: `${img11}`,
        name: "Camp Caravan 11",
        price: "450,000",
        company: "행복 바퀴벌레",
        rate: "6.4",
        license: "2종 보통",
        people: "4인",
        type: "피아트 듀카토",
        location: "수도권",
      },
      docs: {
        comfort: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.
        `,
        tidying: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.`,
        ventilated: `In our vans and motorhomes, all our body lighting is 100% LED: a guarantee of energy efficiency and durability.
As for ventilation, we also often offer double ventilation in the bathroom (Skylight + window), which is very popular with our customers…
Camper relies on legendary Seitz windows. They offer the best insulation and are also the easiest to use and safest because they can’t be removed from the outside.												
					`,
        access: `We add little touches that your joints will thank you for…
          All our motorhomes are equipped with a built-in step to facilitate entry to the vehicle
          All our vans are equipped with an electric step.`,
        heating: `Fuel heating is now very popular in the industry, but this was not always the case; Camper (once again) led the way in this area over 15 years ago.
          One of the advantages it offers is that it can heat up your vehicle while you drive, for a more comfortable arrival.`,
      },
    },
    {
      id: 12,
      caravan: {
        image: `${img12}`,
        name: "Camp Caravan 12",
        price: "100,000",
        company: "모험의 바퀴",
        rate: "1.0",
        license: "1종 보통",
        people: "3인",
        type: "피아트 듀카토",
        location: "경상권",
      },
      docs: {
        comfort: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.
        `,
        tidying: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.`,
        ventilated: `In our vans and motorhomes, all our body lighting is 100% LED: a guarantee of energy efficiency and durability.
As for ventilation, we also often offer double ventilation in the bathroom (Skylight + window), which is very popular with our customers…
Camper relies on legendary Seitz windows. They offer the best insulation and are also the easiest to use and safest because they can’t be removed from the outside.												
					`,
        access: `We add little touches that your joints will thank you for…
          All our motorhomes are equipped with a built-in step to facilitate entry to the vehicle
          All our vans are equipped with an electric step.`,
        heating: `Fuel heating is now very popular in the industry, but this was not always the case; Camper (once again) led the way in this area over 15 years ago.
          One of the advantages it offers is that it can heat up your vehicle while you drive, for a more comfortable arrival.`,
      },
    },
    {
      id: 13,
      caravan: {
        image: `${img13}`,
        name: "Camp Caravan 13",
        price: "900,250",
        company: "모험의 바퀴",
        rate: "9.8",
        license: "1종 보통",
        people: "4인",
        type: "피아트 듀카토",
        location: "충청권",
      },
      docs: {
        comfort: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.
        `,
        tidying: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.`,
        ventilated: `In our vans and motorhomes, all our body lighting is 100% LED: a guarantee of energy efficiency and durability.
As for ventilation, we also often offer double ventilation in the bathroom (Skylight + window), which is very popular with our customers…
Camper relies on legendary Seitz windows. They offer the best insulation and are also the easiest to use and safest because they can’t be removed from the outside.												
					`,
        access: `We add little touches that your joints will thank you for…
          All our motorhomes are equipped with a built-in step to facilitate entry to the vehicle
          All our vans are equipped with an electric step.`,
        heating: `Fuel heating is now very popular in the industry, but this was not always the case; Camper (once again) led the way in this area over 15 years ago.
          One of the advantages it offers is that it can heat up your vehicle while you drive, for a more comfortable arrival.`,
      },
    },
    {
      id: 14,
      caravan: {
        image: `${img14}`,
        name: "Camp Caravan 14",
        price: "2,000,000",
        company: "행복 바퀴벌레",
        rate: "10",
        license: "1종 보통",
        people: "4인",
        type: "르노마스터",
        location: "충청권",
      },
      docs: {
        comfort: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.
        `,
        tidying: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.`,
        ventilated: `In our vans and motorhomes, all our body lighting is 100% LED: a guarantee of energy efficiency and durability.
As for ventilation, we also often offer double ventilation in the bathroom (Skylight + window), which is very popular with our customers…
Camper relies on legendary Seitz windows. They offer the best insulation and are also the easiest to use and safest because they can’t be removed from the outside.												
					`,
        access: `We add little touches that your joints will thank you for…
          All our motorhomes are equipped with a built-in step to facilitate entry to the vehicle
          All our vans are equipped with an electric step.`,
        heating: `Fuel heating is now very popular in the industry, but this was not always the case; Camper (once again) led the way in this area over 15 years ago.
          One of the advantages it offers is that it can heat up your vehicle while you drive, for a more comfortable arrival.`,
      },
    },
    {
      id: 15,
      caravan: {
        image: `${img15}`,
        name: "Camp Caravan 15",
        price: "650,000",
        company: "모험의 바퀴",
        rate: "5.2",
        license: "2종 보통",
        people: "5인",
        type: "포터",
        location: "수도권",
      },
      docs: {
        comfort: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.
        `,
        tidying: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.`,
        ventilated: `In our vans and motorhomes, all our body lighting is 100% LED: a guarantee of energy efficiency and durability.
As for ventilation, we also often offer double ventilation in the bathroom (Skylight + window), which is very popular with our customers…
Camper relies on legendary Seitz windows. They offer the best insulation and are also the easiest to use and safest because they can’t be removed from the outside.												
					`,
        access: `We add little touches that your joints will thank you for…
          All our motorhomes are equipped with a built-in step to facilitate entry to the vehicle
          All our vans are equipped with an electric step.`,
        heating: `Fuel heating is now very popular in the industry, but this was not always the case; Camper (once again) led the way in this area over 15 years ago.
          One of the advantages it offers is that it can heat up your vehicle while you drive, for a more comfortable arrival.`,
      },
    },
    {
      id: 16,
      caravan: {
        image: `${img16}`,
        name: "Camp Caravan 16",
        price: "850,000",
        company: "행복 바퀴벌레",
        rate: "9.0",
        license: "2종 보통",
        people: "6인",
        type: "피아트 듀카토",
        location: "충청권",
      },
      docs: {
        comfort: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.
        `,
        tidying: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.`,
        ventilated: `In our vans and motorhomes, all our body lighting is 100% LED: a guarantee of energy efficiency and durability.
As for ventilation, we also often offer double ventilation in the bathroom (Skylight + window), which is very popular with our customers…
Camper relies on legendary Seitz windows. They offer the best insulation and are also the easiest to use and safest because they can’t be removed from the outside.												
					`,
        access: `We add little touches that your joints will thank you for…
          All our motorhomes are equipped with a built-in step to facilitate entry to the vehicle
          All our vans are equipped with an electric step.`,
        heating: `Fuel heating is now very popular in the industry, but this was not always the case; Camper (once again) led the way in this area over 15 years ago.
          One of the advantages it offers is that it can heat up your vehicle while you drive, for a more comfortable arrival.`,
      },
    },
    {
      id: 17,
      caravan: {
        image: `${img17}`,
        name: "Camp Caravan 17",
        price: "745,000",
        company: "캐러반 여행",
        rate: "7,6",
        license: "1종 보통",
        people: "4인",
        type: "피아트 듀카토",
        location: "수도권",
      },
      docs: {
        comfort: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.
        `,
        tidying: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.`,
        ventilated: `In our vans and motorhomes, all our body lighting is 100% LED: a guarantee of energy efficiency and durability.
As for ventilation, we also often offer double ventilation in the bathroom (Skylight + window), which is very popular with our customers…
Camper relies on legendary Seitz windows. They offer the best insulation and are also the easiest to use and safest because they can’t be removed from the outside.												
					`,
        access: `We add little touches that your joints will thank you for…
          All our motorhomes are equipped with a built-in step to facilitate entry to the vehicle
          All our vans are equipped with an electric step.`,
        heating: `Fuel heating is now very popular in the industry, but this was not always the case; Camper (once again) led the way in this area over 15 years ago.
          One of the advantages it offers is that it can heat up your vehicle while you drive, for a more comfortable arrival.`,
      },
    },
    {
      id: 18,
      caravan: {
        image: `${img18}`,
        name: "Camp Caravan 18",
        price: "125,000",
        company: "캐러반 여행",
        rate: "1.5",
        license: "1종 보통",
        people: "3인",
        type: "르노마스터",
        location: "충청권",
      },
      docs: {
        comfort: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.
        `,
        tidying: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.`,
        ventilated: `In our vans and motorhomes, all our body lighting is 100% LED: a guarantee of energy efficiency and durability.
As for ventilation, we also often offer double ventilation in the bathroom (Skylight + window), which is very popular with our customers…
Camper relies on legendary Seitz windows. They offer the best insulation and are also the easiest to use and safest because they can’t be removed from the outside.												
					`,
        access: `We add little touches that your joints will thank you for…
          All our motorhomes are equipped with a built-in step to facilitate entry to the vehicle
          All our vans are equipped with an electric step.`,
        heating: `Fuel heating is now very popular in the industry, but this was not always the case; Camper (once again) led the way in this area over 15 years ago.
          One of the advantages it offers is that it can heat up your vehicle while you drive, for a more comfortable arrival.`,
      },
    },
    {
      id: 19,
      caravan: {
        image: `${img19}`,
        name: "Camp Caravan 19",
        price: "520,000",
        company: "모험의 바퀴",
        rate: "6.3",
        license: "1종 보통",
        people: "4인",
        type: "피아트 듀카토",
        location: "충청권",
      },
      docs: {
        comfort: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.
        `,
        tidying: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.`,
        ventilated: `In our vans and motorhomes, all our body lighting is 100% LED: a guarantee of energy efficiency and durability.
As for ventilation, we also often offer double ventilation in the bathroom (Skylight + window), which is very popular with our customers…
Camper relies on legendary Seitz windows. They offer the best insulation and are also the easiest to use and safest because they can’t be removed from the outside.												
					`,
        access: `We add little touches that your joints will thank you for…
          All our motorhomes are equipped with a built-in step to facilitate entry to the vehicle
          All our vans are equipped with an electric step.`,
        heating: `Fuel heating is now very popular in the industry, but this was not always the case; Camper (once again) led the way in this area over 15 years ago.
          One of the advantages it offers is that it can heat up your vehicle while you drive, for a more comfortable arrival.`,
      },
    },
    {
      id: 20,
      caravan: {
        image: `${img20}`,
        name: "Camp Caravan 20",
        price: "600,500",
        company: "캐러반 여행",
        rate: "9.5",
        license: "2종 보통",
        people: "6인",
        type: "포터",
        location: "수도권",
      },
      docs: {
        comfort: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.
        `,
        tidying: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi. Turpis ac ut metus, amet, pulvinar. Eget amet dictum luctus quis molestie tellus vitae.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum nibh urna sagittis arcu natoque lectus cursus felis. Nec, felis risus, id in proin sed proin iaculis mi.`,
        ventilated: `In our vans and motorhomes, all our body lighting is 100% LED: a guarantee of energy efficiency and durability.
As for ventilation, we also often offer double ventilation in the bathroom (Skylight + window), which is very popular with our customers…
Camper relies on legendary Seitz windows. They offer the best insulation and are also the easiest to use and safest because they can’t be removed from the outside.												
					`,
        access: `We add little touches that your joints will thank you for…
          All our motorhomes are equipped with a built-in step to facilitate entry to the vehicle
          All our vans are equipped with an electric step.`,
        heating: `Fuel heating is now very popular in the industry, but this was not always the case; Camper (once again) led the way in this area over 15 years ago.
          One of the advantages it offers is that it can heat up your vehicle while you drive, for a more comfortable arrival.`,
      },
    },
  ],
};
